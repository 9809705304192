<template>
  <div
    :style="{ height: clientHeight - 180 + 'px' }"
    style="overflow-y: scroll"
  >
    <!-- 搜索框 -->
    <div class="sousuo">
      <el-input
        id="input1"
        v-model="search"
        placeholder="请输入内容"
        style="width: 100px"
      ></el-input>
      <img class="imgs" src="../../assets/images/query.png" alt />
    </div>
    <!-- 表格 -->
    <div class="tables">
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="康康ID" width="90"></el-table-column>
        <el-table-column
          prop="createId"
          label="创建ID"
          width="180"
        ></el-table-column>
        <!-- <el-table-column prop="name" label="性别"></el-table-column> -->
        <el-table-column prop="createDate" label="创建时间"></el-table-column>
        <el-table-column prop="updateId" label="修改ID"></el-table-column>
        <el-table-column prop="updateDate" label="修改时间"></el-table-column>
        <el-table-column prop="delFlag" label="删除标识"></el-table-column>
        <el-table-column prop="operation" label="操作">
          <template>
            <el-button
              class="details"
              size="mini"
              @click="dialogVisible = true"
            >
              <router-link to="/dictionary/SonDictionary">详情</router-link>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="buttons">
        <!-- 分页 -->
        <el-pagination
          :page-size="pagesize"
          :pager-count="pagercount"
          layout="slot, pager"
          :total="100"
        >
          <img class="lefts" src="../../assets/images/leftpage.png" alt />
          <img class="rights" src="../../assets/images/rightpage.png" alt />
        </el-pagination>
        <!-- 按钮 -->
        <div class="annius">
          <el-button>数据分析</el-button>
          <el-button>数据导出</el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { api } from "../../api1/config";
export default {
  data() {
    return {
      clientHeight: document.body.clientHeight,
      search: "",
      pagesize: 1,
      pagercount: 5,
      //表格数据
      tableData: [],
      pageSize: 10,
      totalCount: 1000,
    };
  },
  created() {
    this.dictList(1);
  },
  mounted() {
    const that = this;
    window.onresize = () => {
      return (() => {
        window.screenHeight = document.body.clientHeight;
        that.clientHeight = window.screenHeight;
      })();
    };
  },
  watch: {
    clientHeight(val) {
      // 为了避免频繁触发resize函数导致页面卡顿，使用定时器
      if (!this.timer) {
        // 一旦监听到的screenWidth值改变，就将其重新赋给data里的screenWidth
        this.clientHeight = val;
        this.timer = true;
        let that = this;
        setTimeout(function () {
          // 打印screenWidth变化的值
          console.log(that.clientHeight);
          that.timer = false;
        }, 400);
      }
    },
  },
  methods: {
    //字典列表
    dictList(page) {
      api.bigDictList({ pageCount: page }).then((res) => {
        console.log("大类字典：", res.data);
        if (res.data.code === 200) {
          this.tableData = res.data.result.data;
          this.totalCount = res.data.result.page.totalCount;
          this.pageSize = res.data.result.page.pageSize;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.sousuo {
  display: flex;
  align-items: center;
  margin-left: 40px;
  margin-top: 20px;
  margin-bottom: 20px;
}
/deep/.el-input__inner {
  border-radius: 0;
  height: 33px;
  line-height: 33px;
}
.imgs {
  width: 49px;
}
// table
.tables {
  margin-right: 40px;
  margin-left: 25px;
  .el-table::before {
    height: 0;
  }
  .el-table {
    td,
    th.is-leaf {
      border: none !important;
    }
    tr th {
      font-size: 14px;
      color: #909399;
      text-align: center;
    }
    tr td {
      text-align: center;
      font-size: 14px;
      color: #606266;
    }
    .details {
      height: 30px;
      border-radius: 15px;
    }
  }
  // 按钮  分页
  .buttons {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-right: 70px;
    .annius {
      .el-button {
        width: 130px;
        height: 40px;
        line-height: 40px;
        border-radius: 25px;
        margin-top: 20px;
        margin-right: 20px;
        background: #d78673;
        color: #ffffff;
        font-size: 16px;
        padding: 0px;
      }
    }
    // 分页
    .el-pagination {
      padding: 0;
      margin-top: 20px;
      .el-pager,
      .el-pager li {
        margin-top: 2px;
        color: #afafaf;
        min-width: 50px;
        font-size: 17px;
        font-weight: normal;
      }
      .el-pager li.active {
        color: #d78673;
      }
      .el-pager li:hover {
        color: #d78673;
      }
      img {
        display: inline-block;
      }
      .lefts {
        margin-right: 25px;
      }
      .rights {
        float: right;
        margin-left: 25px;
      }
    }
  }
}
/deep/ .el-table .el-table__cell {
  padding: 12px 0;
  min-width: 0;
  box-sizing: border-box;
  text-overflow: ellipsis;
  vertical-align: middle;
  position: relative;
  text-align: center;
}
/deep/ .el-table td.el-table__cell,
.el-table th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/ th.el-table__cell.is-leaf {
  border-bottom: none;
}
/deep/.el-table td,
.el-table th.is-leaf {
  border-bottom: none !important;
}
/deep/.el-table .cell {
  text-align: center !important;
}
/deep/.el-table thead tr th.is-leaf {
  border: none;
  border-right: none;
}
</style>